<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
    <!-- 扫码出标信息 -->
    <ScanOrderInfo
      :visible="showScanInfo"
      :scanOrderInfo="scanMsg"
      @onClose="hideScanInfo"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { getSiteConfig, getConfigKey } from "@/api/system/config"
import ScanOrderInfo from "@/views/customProduct/mine/components/orderScan/OrderInfo"

export default {
  name: "App",
  components: {
    ScanOrderInfo
  },
  computed: {
    ...mapGetters(["user"]),
    routeName() {
      return this.$route.path
    },
    scanMsg() {
      return this.$store.state.webSocket.message
    }
  },
  mounted() {
    this.getSiteConfig()

    window.setInterval(() => {
      if (
        this.routeName == "/map/map_mng/imageCommon" ||
        this.routeName == "/design/online/tool"
      ) {
        window.document.oncontextmenu = function() {
          return false
        }
      } else {
        window.document.oncontextmenu = function() {
          return true
        }
      }
    }, 1000)
  },
  provide() {
    return {
      reload: this.reload,
    }
  },

  data() {
    return {
      isRouterAlive: true,
      showScanInfo: false,
    }
  },
  watch: {
    scanMsg(newValue) {
      if (newValue && newValue.picUrl) {
        this.showScanInfo = true
      }
    },
    user: {
      handler(newValue) {
        if (newValue && newValue.userType == '00') { //登录且管理员端
          // 登录状态下直接连接，未登录状态在登录后连接
          getConfigKey('scan_order_show').then(res => {
            if (res.code == 200) {
              if (res.msg == '01') {
                this.$store.dispatch('onopenWebSocket')
              }
            }
          })
        }
      },
      deep: true,
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function() {
        this.isRouterAlive = true
      })
    },
    async getSiteConfig() {
      let result = await getSiteConfig()
      this.$store.commit("SET_SITE_DATA", result.data)
    },
    hideScanInfo() {
      this.showScanInfo = false
    }
  },
  beforeDestroy() {
    this.$store.dispatch('disconnectWebSocket')
  },
  destroyed() {
    this.$store.dispatch('disconnectWebSocket')
  },
}
</script>

<style>
html,
body,
#app {
  height: 100%;
  background-color: white;
  font-family: sans-serif;
}
body {
  margin: initial;
  min-width: 1280px;
}
#nprogress .bar {
  background: #ff6c02 !important;
}
</style>
