import CryptoJS from 'crypto-js'
let socket = null
let timer = null
let status = false //控制频率

const webSocket = {
    state: {
        message: {},
        close: false,
        time: 30000,
        retryDelay: 1000,
        maxRetries: 3,
        retryCount: 0,
    },
    mutations: {
        // 开启
        SOCKET_ONOPEN(state, event) {
            console.log('开启WebSocket连接')
            state.retryCount = 0
            // 开启心跳定时器
            timer = setInterval(() => {
                socket.send('heartbeat')// 发送心跳消息
            }, state.time)
        },
        // 关闭
        SOCKET_ONCLOSE(state, event) {
            console.log('WebSocket连接已关闭')
            if (state.close) {
                // 浏览器关闭
                clearInterval(timer)// 关闭心跳定时器
                // socket.send('close')// 发送心跳消息
            } else {
                if (state.retryCount > state.maxRetries) {
                    if (status) {
                        return
                    }
                    status = true
                    this._vm.$confirm('连接失败，是否重新连接', "连接扫码枪", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    }).then(res => {
                        state.retryCount = 0
                        status = false
                        this.commit('AGAINREQUEST')
                    })
                } else {
                    this.commit('AGAINREQUEST')
                }
            }
        },
        // 收到消息
        SOCKET_ONMESSAGE(state, message) {
            const info = localStorage.getItem('scanInfo')
            if (info) {
                let data = JSON.parse(info)
                const id = data.factory + data.sign + 'b'
                if (id == message.messageId) {
                    state.message = message.backendData
                }
            }
            console.log('收到消息:', message)
        },
        // 连接错误
        // SOCKET_ONERROR(state, event) {
        //     console.error('WebSocket连接错误:', event)
        //     this.commit('AGAINREQUEST')
        // },
        // 重新开启ws
        AGAINREQUEST(state) {
            console.log('重新开启ws')
            clearInterval(timer)// 关闭心跳定时器
            setTimeout(() => {
                this.dispatch('onopenWebSocket')
            }, state.retryDelay)
        }
    },
    actions: {
        // 创建WebSocket连接
        onopenWebSocket ({ state, commit }, data) {
            // let hasExecuted = false
            const info = localStorage.getItem('scanInfo')
            if (info) {
                ++state.retryCount
                let data = JSON.parse(info)
                const MD5Content = CryptoJS.MD5(`${data.factory}temuOne998*`).toString()
                // socket = new WebSocket(`wss://${window.location.host}/prod-api/ws/message/${data.factory}-${MD5Content}-${data.sign}-b`)
                socket = new WebSocket(`wss://internal.temudiy.com/prod-api/ws/message/${data.factory}-${MD5Content}-${data.sign}-b`) //指定环境测试用
                // 监听WebSocket事件
                socket.onopen = (event) => {
                //   console.log('监听WebSocket事件-----连接ws', event)
                  commit('SOCKET_ONOPEN', event)
                }
                socket.onclose = (event) => {
                //   console.log('监听WebSocket事件-----关闭ws', event)
                //   if (!hasExecuted) {
                    commit('SOCKET_ONCLOSE', event)
                    // hasExecuted = true
                //   }
                }
                socket.onmessage = (event) => {
                //   console.log('监听WebSocket事件-----接受消息',event)
                  commit('SOCKET_ONMESSAGE', JSON.parse(event.data))
                }
                // socket.onerror = (event) => {
                //   console.log('监听WebSocket事件-----连接ws错误')
                // //   if (!hasExecuted) {
                //     commit('SOCKET_ONERROR', event)
                // //     hasExecuted = true
                // //   }
                // }
            }
          },
        // 关闭浏览器  关闭WebSocket连接
        disconnectWebSocket ({ state }) {
            if (socket) {
                state.close = true
                // socket.send('close')// 发送心跳消息
                socket.close()
            }
        }
    }
}

export default webSocket


