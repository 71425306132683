
<template>
    <el-dialog :close-on-click-modal="false"  
      title="扫码出标信息"
      :visible.sync="visible"
      width="900px"
      class="scan_order_info"
      :before-close="handleClose"
      
    >
      <el-row :gutter="20" style="display: flex;align-items: center;">
        <el-col :span="12">
          <el-image fit="contain" style="cursor: pointer;" v-if="scanOrderInfo?.picUrl?.length>0" :src="scanOrderInfo.picUrl[0]" @click="handleShowPicMaps(scanOrderInfo.picUrl)"></el-image>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8"><div class="grid-content bg-purple">备货单号：</div></el-col>
            <el-col :span="16"><div class="grid-content bg-purple">{{scanOrderInfo.subOrder}}</div></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8"><div class="grid-content bg-purple">定制SKU ID：</div></el-col>
            <el-col :span="16"><div class="grid-content bg-purple">{{ scanOrderInfo.dzSkuId }}</div></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8"><div class="grid-content bg-purple">产品名称：</div></el-col>
            <el-col :span="16"><div class="grid-content bg-purple">{{ scanOrderInfo.proName }}</div></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8"><div class="grid-content bg-purple">包裹号：</div></el-col>
            <el-col :span="16"><div class="grid-content bg-purple">{{ scanOrderInfo.packageSn }}</div></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8"><div class="grid-content bg-purple">颜色尺码：</div></el-col>
            <el-col :span="16"><div class="grid-content bg-purple">{{ scanOrderInfo.properVal }}</div></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8"><div class="grid-content bg-purple">数量：</div></el-col>
            <el-col :span="16"><div class="grid-content bg-purple">{{ scanOrderInfo.num }}</div></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8"><div class="grid-content bg-purple">套数：</div></el-col>
            <el-col :span="16"><div class="grid-content bg-purple">{{ scanOrderInfo.taoshu }}</div></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="8"><div class="grid-content bg-purple">件数：</div></el-col>
            <el-col :span="16"><div class="grid-content bg-purple">{{ scanOrderInfo.pics }}</div></el-col>
          </el-row>
        </el-col>
      </el-row>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose" size="small">返回</el-button>
      </span> -->

      <!-- 图片预览 -->
      <el-dialog
        :close-on-click-modal="false"
        title="图片预览"
        :visible.sync="previewImagesVisible"
        width="800px"
        >
        <div class="preview-area">
            <div class="carousel">
                <el-carousel
                    ref="carouselPreview"
                    style="height: 500px; overflow: hidden"
                    :autoplay="false"
                    :interval="5000"
                    arrow="always"
                >
                    <el-carousel-item
                    style="height: 500px;"
                    v-for="(productImage, index) in productImageList"
                    :key="index"
                    >
                    <img :src="productImage" />
                    </el-carousel-item>
                </el-carousel>
            </div>

            <div class="preview-list">
            <div
                v-for="(productImage, index) in productImageList"
                :key="index"
                class="preview-list-item"
                :class="{ 'is-active': activeCarousel === index }"
                @click="handleClickPreviewImage(index)"
            >
                <img :src="productImage" />
                <div class="preview-text">图片{{ index + 1 }}</div>
            </div>
            </div>
        </div>
      </el-dialog>

    </el-dialog>
  </template>
  
  <script>
  
  export default {
    name: "SetDevice",
    data() {
      return {
        // scanOrderInfo: {}
        previewImagesVisible: false,
        productImageList: [],
        activeCarousel: 0,
      }
    },
    props: {
      visible: {
        type: Boolean,
        default() {
          return false;
        },
      },
      scanOrderInfo: {
        type: Object,
        default: () => {}
      }
    },
    computed:{

    },
    methods: {
      handleClose() {
        this.$emit("onClose")
      },
      handleShowPicMaps(list) {
          this.productImageList = []
          for (let record of list) {
              this.productImageList.push(record)
          }
          this.activeCarousel = 0
          this.previewImagesVisible = true
          this.$nextTick(() => {
              this.$refs["carouselPreview"].setActiveItem(0)
          })
      },
      handleClickPreviewImage(index) {
          this.$refs["carouselPreview"].setActiveItem(index)
          this.activeCarousel = index
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  .scan_order_info{
    .el-col-12 {
      font-size: 16px;
    }

    // 图片预览
    .preview-area {
        overflow: hidden;
        .carousel {
            width: 600px;
            float: left;
            .el-carousel__item {
            text-align: center;
            }
            ::v-deep .el-carousel__container {
            height: 500px;
            }
            ::v-deep .el-carousel__indicators {
            display: none;
            }
            img {
            // width: 500px;
            height: 500px;
            }
        }
        .preview-list {
            width: 130px;
            float: left;
            height: 500px;
            margin: 0 0 0 16px;
            overflow-y: auto;
            .preview-list-item {
            border: 1px solid #e5e5e5;
            margin: 0 8px 15px 0;
            box-sizing: border-box;
            width: 111px;
            height: 111px;
            position: relative;
            cursor: pointer;
            text-align: center;
            .preview-text {
                display: none;
                position: absolute;
                bottom: 0;
                left: 0;
                text-align: center;
                width: 100%;
                background: rgba(0, 0, 0, 0.4);
                color: #fff;
                padding: 0.05rem 0;
                font-size: 0.14rem;
                z-index: 10;
                font-size: 14px;
            }
            &:hover .preview-text {
                display: block;
            }
            &.is-active .preview-text {
                display: block;
                background: #4934b2;
            }
            }

            img {
            // width: 109px;
            height: 109px;
            }
            .is-active {
            border: 1px solid #4934b2;
            }
        }
        .color-picker {
            overflow: hidden;
            margin: 16px 8px 0 0;
            ul {
            overflow: hidden;
            padding: 0px 0 0 266px;
            }
        }

        .color-picker-list li,
        .color-picker-list-hover li {
            background-color: #fff;
            width: 24px;
            height: 24px;
            border-radius: 4px;
            border: #d6d2d2 solid 1px;
            margin-left: 2px;
            padding: 1px;
            float: left;
        }
        .color-picker-list li.cur {
            border: #4934b2 solid 2px;
        }
        .color-picker-list li a,
        .color-picker-list-hover li a {
            width: 24px;
            height: 24px;
            display: table;
        }
    }
  }
  </style>
  