import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import tagsView from './modules/tagsView'
import permission from './modules/permission'
import order from  './modules/order'
import product from './modules/product'
import repository from './modules/repository'
import setting from './modules/setting'
import custom from './modules/custom'
import webSocket from './modules/webSocket'
import getters from './getters'


Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    user,
    tagsView,
    order,
    permission,
    product,
    repository,
    setting,
    custom,
    webSocket
  },
  getters
})

export default store
