import request from "@/utils/request"

// 查询产品管理列表
export function getProductList(params) {
  return request({
    url: "/server/product/list",
    method: "get",
    params: params,
  })
}
// 查询产品管理列表
export function addProduct(data) {
  return request({
    url: "/server/product/add",
    method: "post",
    data: data,
  })
}
// 查询产品详情
export function getProductDetail(data) {
  return request({
    url: "/server/product/getInfo/" + data,
    method: "get",
  })
}
// 查询产品管理列表
export function editProduct(data) {
  return request({
    url: "/server/product/edit",
    method: "post",
    data: data,
  })
}
// 新品速递
export function listNew(data) {
  return request({
    url: "/server/product/listNew",
    method: "GET",
    params: data,
  })
}
// 热卖推荐
export function listSale(data) {
  return request({
    url: "/server/product/listSale",
    method: "GET",
    params: data,
  })
}

// 首页数据统计
export function getOverview(data) {
  return request({
    url: "/server/stat/getOverview",
    method: "GET",
    params: data,
  })
}

// 首页数据统计
export function getOrderNumWithDay(data) {
  return request({
    url: "/server/stat/getOrderNumWithDay",
    method: "GET",
    params: data,
  })
}

//首页收入统计今日昨天交易、退款
export function getIncomeStat(data) {
  return request({
    url: "/server/stat/getIncomeStat",
    method: "GET",
    params: data,
  })
}

// 首页收入统计近7天交易数据
export function getLast7Days(data) {
  return request({
    url: "/server/stat/getLast7Days",
    method: "GET",
    params: data,
  })
}

// 删除产品缓存
export function cleaCache(data) {
  return request({
    url: "/server/product/cleaCache/" + data,
    method: "POST",
  })
}

// 获取编辑接口
export function getEditInfo(data) {
  return request({
    url: "/server/product/getEditInfo/" + data,
    method: "GET",
  })
}

// 获取编辑接口
export function getAdminStatWithDay(params) {
  return request({
    url: "/server/stat/getAdminStatWithDay",
    method: "GET",
    params: params,
  })
}

// 获取编辑接口
export function getAdminTodoWithDay(data) {
  return request({
    url: "/server/stat/getAdminTodoWithDay",
    method: "GET",
  })
}

// 获取编辑接口
export function getAdminChargeWithDay(data) {
  return request({
    url: "/server/stat/getAdminChargeWithDay",
    method: "GET",
    params: data,
  })
}
// 获取编辑接口
export function getAdminMerchantWithDay(data) {
  return request({
    url: "/server/stat/getAdminMerchantWithDay",
    method: "GET",
    params: data,
  })
}
// 获取编辑接口
export function getAdminOrderWithDay(data) {
  return request({
    url: "/server/stat/getAdminOrderWithDay",
    method: "GET",
    params: data,
  })
}

// 获取编辑接口
export function addBatch(data) {
  return request({
    url: "/server/product/addBatch",
    method: "POST",
    data: data,
  })
}

// 获取刺绣接口
export function getEmbroideryPrice(data) {
  return request({
    url: "/server/product/getEmbroideryPrice/" + data,
    method: "GET",
  })
}

// 获取刺绣接口
export function getInviterMerchantStat(data) {
  return request({
    url: "/server/stat/getInviterMerchantStat",
    method: "GET",
    params: data,
  })
}


// 获取刺绣接口
export function uploadGy(data) {
  return request({
    url: "/product/jst/uploadGy",
    method: "POST",
    data: data,
  })
}
// 获取刺绣接口
export function getDict(data) {
  return request({
    url: "/server/product/getDict",
    method: "GET",
    params: data,
  })
}
// 获取刺绣接口
export function setProductCat(data) {
  return request({
    url: "/server/product/setProductCat",
    method: "POST",
    data: data,
  })
}
// 查询价格
export function getUserPrice(data) {
  return request({
    url: "/server/product/getUserPrice",
    method: "get",
    params: data,
  })
}
// 设置价格
export function setUserPrice(data) {
  return request({
    url: "/server/product/setUserPrice",
    method: "post",
    data: data,
  })
}
