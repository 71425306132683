<template>
  <div
    class="register-2017-body"
    data-spm-anchor-id="a2c44.11131956.0.i2.168a5455pOElMx"
  >
    <div class="register-width iframe-loaded" id="register">
      <div class="title-box">
        <!-- 商户端 -->
        <div class="head">用户登录</div>
      </div>
      <div id="container" data-spm-anchor-id="0.0.0.i0.a10834dc9MfOkc">
        <div data-reactroot="" class="schema-form">
          <form class="next-form next-form-left ver next-form-large">
            <div class="form-content">
              <el-form
                :model="loginForm"
                :rules="registerRules"
                ref="loginForm"
                label-width="0px"
              >
                <el-form-item prop="username">
                  <el-input
                    v-model="loginForm.username"
                    placeholder="请输入账号"
                  >
                    <template slot="prepend"
                      ><i class="el-icon-user"></i
                    ></template>
                  </el-input>
                </el-form-item>

                <el-form-item prop="password">
                  <el-input
                    type="password"
                    v-model="loginForm.password"
                    placeholder="请输入登录密码"
                  >
                    <template slot="prepend"
                      ><i class="el-icon-lock"></i
                    ></template>
                  </el-input>
                </el-form-item>
                <el-form-item prop="code">
                  <el-input
                    style="width: calc(100% - 119px); float: left"
                    v-model="loginForm.code"
                    placeholder="请输入验证码"
                    @keyup.enter.native="handleRegister"
                  >
                  </el-input>
                  <img
                    style="float: left; height: 40px; margin-left: 12px"
                    :src="codeUrl"
                    @click="getCode"
                  />
                </el-form-item>
              </el-form>
              <div style="text-align: right; margin: -16px 0 0 0">
                <img style="width: 100px" :src="shoes" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="button">
      <img :src="loginButton" @click="handleLogin" />
    </div>
    <div style="padding: 10px 10%; font-size: 14px; text-align: center">
      <!-- <div @click="handleJump('/reset')" style="float: left; color: #ffffff">
        忘记密码
      </div> -->
      <div @click="handleJump('/app_register')" style="color: #00bbf9">
        免费注册
      </div>
    </div>
    <el-dialog
      :before-close="handleClose"
      title="登录成功"
      :visible.sync="dialogVisible"
      width="60%"
    >
      <span>建议您使用PC端操作工作台</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="small" @click="handleClose">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getCodeImg, register, sendSms } from "@/api/login"
import shoes from "@/assets/images/index/shoes.png"
import loginButton from "@/assets/images/index/loginButton.png"
import { mapGetters } from "vuex"

export default {
  name: "register",
  data() {
    //确认密码校验
    let validateConfirmPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"))
      } else if (value !== this.loginForm.password) {
        callback(new Error("两次输入密码不一致!"))
      } else {
        callback()
      }
    }
    let validatePhone = (rule, value, callback) => {
      var reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (value === "") {
        callback(new Error("请输入手机号"))
      } else if (!reg_tel.test(value)) {
        callback(new Error("请输入正确的手机号"))
      } else {
        callback()
      }
    }
    return {
      codeUrl: "",
      cookiePassword: "",
      loginForm: {
        username: "",
        password: "",
        phoneNumber: "",
        confirmPassword: "",
        code: "",
      },
      registerRules: {
        username: [{ required: true, trigger: "blur", message: "请输入账号" }],
        phoneNumber: [
          {
            required: true,
            validator: validatePhone,
            trigger: "blur",
          },
        ],
        password: [{ required: true, trigger: "blur", message: "请输入密码" }],
        confirmPassword: [
          {
            required: true,
            validator: validateConfirmPassword,
            trigger: "blur",
          },
        ],
        code: [{ required: true, trigger: "change", message: "请输入验证码" }],
      },
      loading: false,
      isSend: false,
      remain: 60,
      checked: false,
      shoes: shoes,
      loginButton: loginButton,
      dialogVisible: false,
      userType: "",
    }
  },
  computed: {
    ...mapGetters(["dictData", "dictMap", "token", "user"]),
  },

  computed: {
    showMeta() {
      if (document.location.host == "chuanghe.onecust.com") {
        return true
      } else {
        return false
      }
    },
  },
  created() {
    this.getCode()
  },
  methods: {
    getCode() {
      getCodeImg().then((res) => {
        this.codeUrl = "data:image/gif;base64," + res.img
        this.loginForm.uuid = res.uuid
      })
    },
    handleJump(path) {
      this.$router.push({
        path: path,
      })
    },
    handleClose() {
      this.dialogVisible = false
      this.$router.push({ path: "/index" })
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          if (this.$store.getters.token) {
            this.dialogVisible = true
          } else {
            this.$store
              .dispatch("Login", this.loginForm)
              .then((userType) => {
                this.userType = userType
                this.dialogVisible = true
                getDictData().then((result) => {
                  this.$store.commit("SET_DICT_DATA", result.data)
                })
              })
              .catch(() => {
                this.loading = false
                this.getCode()
              })
          }
        }
      })
    },
    handleJump(path) {
      this.$router.push({ path: path })
    },
  },
  mounted() {},
}
</script>

<style>
.body {
  height: 100%;
}
</style>

<style lang="scss" scoped>
#alibaba-register-box {
  background-color: #f5f5f6;
}
.register-2017-body {
  background-image: url("../../assets/images/index/register_bg.png") !important;
  background-size: cover;
  background-position: center center;
  height: 100%;
}

.register-2017-body {
  padding: 39px 0;
  text-align: center;
  margin: auto;
  width: 100%;
  border: none;
  background-color: #f5f5f6;
  ::v-deep .el-input-group__prepend {
    padding: 0 12px;
    font-size: 18px;
  }
  box-sizing: border-box;
}

#register {
  margin: 0 auto 19px auto;
  border: none;
  margin: 0 8% 0 8%;
  padding: 0 0 0px 0;
  background-color: rgba(255, 255, 255, 0.6);

  font-family: PingFang SC;
  border-radius: 20px;
}
/*
#register ::v-deep .el-input-group__append,
#register ::v-deep .el-input-group__prepend {
  background-color: #ffffff;
  border: none;
}*/

.title-box {
  position: relative;
  margin: 0 auto;
}
.head {
  padding: 29px 0 19px 0;
  font-size: 19px;
  font-weight: bold;
  color: #366e9f;
  letter-spacing: 1px;
  line-height: 36px;
  text-align: center;
}
.tips {
  position: absolute;
  top: 43px;
  right: 40px;
  font-size: 12px;
  color: #73777a;
  letter-spacing: 0.4px;
  line-height: 30px;
  text-align: left;
}
.tips a {
  color: #4934b2;
}
#container {
  position: relative;
  margin: 0 auto;
}
.schema-form {
  margin: 0 auto;
  padding: 0 16px;
}
.next-form {
  color: #333;
  font-size: 12px;
  line-height: 1.28571;
}
.next-form-item {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 18px;
}
.next-form .next-row {
  padding: 0;
}
.next-input {
  display: inline-table;
  border-collapse: separate;
  overflow: visible;
  border: 1px solid #ededed;
  width: 200px;
  border-spacing: 0;
  background-color: #fff;
  transition: all 0.3s ease-out;
}
.next-row .next-col-24 {
  width: 100%;
  max-width: 100%;
  -moz-flex: 0 0 100%;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
}
.next-input input,
.next-input textarea {
  width: 100%;
  border: none;
  outline: none;
  padding: 0;
  font-weight: 400;
  vertical-align: baseline;
  background-color: transparent;
}
.next-input-large input {
  height: 38px;
  line-height: 38px \0;
  margin: 0;
  padding: 0 12px;
  font-size: 12px;
}
.next-input.next-input-single {
  border-color: #ebeef5;
}
.next-form-item-control > .next-input,
.next-form-item-control > .next-input-group {
  width: 100%;
}
.next-form,
.next-form *,
.next-form :after,
.next-form :before {
  box-sizing: border-box;
}
.form-content {
  padding: 0px 0 0px 0;
}
.button {
  margin: 5vh 0 0 0;
  img {
    width: calc(84%);
  }
}
.form-content ::v-deep .el-input-group__append {
  background-color: #4934b2;
  color: #ffffff;
  border: #4934b2;
}
</style>
